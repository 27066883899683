import {
	computed,
	ref,
	getCurrentInstance,
} from '@vue/composition-api';

import COLOR_SETS from '@/assets/data/color-sets.json';
import { useUserStyles } from '@/components/builder-drawers/drawers/partials/stylesDrawer/use/useUserStyles';
import { COLORS } from '@/constants/globalStyles';
import BASE_TEMPLATE from '@/data/BaseTemplate.json';
import { cloneDeep } from '@/utils/object';

const uneditedColorSets = cloneDeep(COLOR_SETS);

// Shared state
const colorSetsList = ref(COLOR_SETS);
const currentColorSet = ref(null);
const uneditedColorSetsList = ref(uneditedColorSets);

export const useColorSetsLibrary = () => {
	const initialInstance = getCurrentInstance();
	const { currentTemplateUneditedStyles } = useUserStyles();
	const { $store } = getCurrentInstance() ?? {};
	const website = computed(() => getCurrentInstance()?.$store?.state?.website
		?? initialInstance.$store?.state?.website);
	const websiteColors = computed(() => website.value?.styles.colors);
	const currentTemplate = computed(() => website.value?.meta.template);
	/**
	 * If colorSetId is not set takes currentTemplate instead as it means
	 * that no style has been selected yet and template id is the currentColorSetId
	 */
	const currentColorSetId = computed(() => website.value?.meta.colorSetId ?? currentTemplate.value);

	const isTemplateColorsInUneditedColorsList = !!uneditedColorSetsList.value
		.some(({ colorSetId }) => colorSetId === currentColorSetId.value);

	const isWebsiteColorsInColorSetList = computed(() => colorSetsList.value
		.some(({ colorSetId }) => currentColorSetId.value === colorSetId));

	const currentUneditedColorSet = computed(() => uneditedColorSetsList
		.value.find(({ colorSetId }) => colorSetId === currentColorSetId.value) ?? {});

	const setCurrentColorSet = (colorSet, colorSetId) => {
		currentColorSet.value = {
			colorSet,
			colorSetId,
		};
	};

	const addColorSetToList = (colorSet, colorSetId) => {
		colorSetsList.value.unshift({
			colorSetId,
			colorSet,
		});
	};

	const addColorSetToUneditedList = (colorSet, colorSetId) => {
		uneditedColorSetsList.value.unshift({
			colorSetId,
			colorSet,
		});
	};

	const updateColorSetListWithWebsiteColors = () => {
		colorSetsList.value = colorSetsList.value
			.filter(({ colorSetId }) => colorSetId !== currentColorSetId.value);

		addColorSetToList(websiteColors.value, currentColorSetId.value);
	};

	const setColorSet = (colorSet, colorSetId) => {
		setCurrentColorSet(colorSet, colorSetId);

		$store.commit('setWebsiteMeta', {
			key: 'colorSetId',
			value: colorSetId,
		});
		$store.commit('setStyleProperties', {
			element: COLORS,
			value: colorSet,
		});
	};

	const resetSelectedPaletteToDefault = () => {
		const { colorSet: uneditedColorSet } = currentUneditedColorSet.value;
		const paletteToResetIndex = colorSetsList.value
			.findIndex(({ colorSetId }) => colorSetId === currentColorSetId.value);

		colorSetsList.value[paletteToResetIndex].colorSet = uneditedColorSet;
	};

	const resetEditedColors = () => {
		setColorSet(currentUneditedColorSet.value.colorSet, currentUneditedColorSet.value.colorSetId);
	};

	const addTemplateColorSetToList = () => {
		setCurrentColorSet(websiteColors.value, currentColorSetId.value);

		if (isWebsiteColorsInColorSetList.value) {
			return;
		}

		addColorSetToList(websiteColors.value, currentColorSetId.value);

		if (isTemplateColorsInUneditedColorsList) {
			return;
		}

		if (currentTemplateUneditedStyles.value) {
			addColorSetToUneditedList(currentTemplateUneditedStyles.value.colors, currentTemplate.value);
		} else {
			addColorSetToUneditedList(BASE_TEMPLATE.styles.colors, currentTemplate.value);
		}
	};

	return {
		addTemplateColorSetToList,
		resetEditedColors,
		setColorSet,
		addColorSetToList,
		resetSelectedPaletteToDefault,
		updateColorSetListWithWebsiteColors,
		currentColorSetId,
		currentColorSet: computed(() => currentColorSet.value),
		uneditedColorSetsList: computed(() => uneditedColorSetsList.value),
		colorSetsList: computed(() => colorSetsList.value),
	};
};
