<template>
	<ZyroDrawer
		class="drawer"
		:class="{ 'loading': isLoadingUserStylesDrawer }"
		close-on-click-outside
		unset-element-on-open
	>
		<ZyroLoader v-if="isLoadingUserStylesDrawer" />
		<div
			v-else
			class="style-list"
			data-qa="builder-sidemenu-globalstyles"
		>
			<div class="drawer__title z-h5">
				<ZyroButton
					v-if="currentPage.component !== 'UserStyles'"
					v-qa="'userstyles-goback-btn'"
					icon="arrow-left"
					icon-dimensions="16px"
					@click="changePreviousDrawerPage(USER_STYLES_DRAWER)"
				/>
				{{ $t(currentPage.title18nPath) }}
			</div>
			<p class="z-body-small style-list__subtext">
				{{ $t(currentPage.subtext18nPath) }}
			</p>

			<Transition
				name="slide-right"
				mode="out-in"
			>
				<Component :is="currentPage.component" />
			</Transition>
		</div>
	</ZyroDrawer>
</template>

<script>
import { onBeforeMount } from '@vue/composition-api';
import { mapState } from 'vuex';

import { useColorSetsLibrary } from '@/components/builder-drawers/drawers/partials/stylesDrawer/colors/use/useColorSetsLibrary';
import { useTypographyStylesLibrary } from '@/components/builder-drawers/drawers/partials/stylesDrawer/typograpghy/use/useTypographyStylesLibrary';
import { useUserStyles } from '@/components/builder-drawers/drawers/partials/stylesDrawer/use/useUserStyles';
import i18n from '@/i18n/setup';
import {
	DRAWER_PAGES,
	MAIN_PAGE_KEY,
	USER_STYLES_TYPOGRAPHY_CATEGORY_SELECT_KEY,
} from '@/store/builder/constants/drawerPages';
import { USER_STYLES_DRAWER } from '@/store/builder/constants/drawers';
import {
	FETCH_GOOGLE_FONTS,
	mapActionsFonts,
} from '@/store/builder/fonts';
import {
	mapActionsGui,
	CHANGE_PREVIOUS_DRAWER_PAGE,
} from '@/store/builder/gui';

const localFileTranslation = { title: i18n.t('builder.userStyles.typography.textStyles') };

export default {
	components: {
		UserStyles: () => import('@/components/builder-drawers/drawers/partials/stylesDrawer/UserStyles.vue'),
		TypographyCategorySelect: () => import('@/components/builder-drawers/drawers/partials/stylesDrawer/typograpghy/TypographyCategorySelect.vue'),
		TypographyTypeSelect: () => import('@/components/builder-drawers/drawers/partials/stylesDrawer/typograpghy/TypographyTypeSelect.vue'),
		ColorPaletteEdit: () => import('@/components/builder-drawers/drawers/partials/stylesDrawer/colors/ColorPaletteEdit.vue'),
	},
	setup() {
		const {
			getCurrentTemplateStyles,
			hasLoadedCurrentTemplateStyles,
			currentTemplateUneditedStyles,
		} = useUserStyles();
		const { addTemplateColorSetToList } = useColorSetsLibrary();
		const { addTemplateTypographyStylesToList } = useTypographyStylesLibrary();

		onBeforeMount(async () => {
			if (!hasLoadedCurrentTemplateStyles.value) {
				await getCurrentTemplateStyles();
				addTemplateColorSetToList();
				addTemplateTypographyStylesToList();
			}
		});

		return {
			addTemplateTypographyStylesToList,
			addTemplateColorSetToList,
			currentTemplateUneditedStyles,
			getCurrentTemplateStyles,
			hasLoadedCurrentTemplateStyles,
			localFileTranslation,
			DRAWER_PAGES,
			MAIN_PAGE_KEY,
			USER_STYLES_TYPOGRAPHY_CATEGORY_SELECT_KEY,
			USER_STYLES_DRAWER,
		};
	},
	computed: {
		...mapState('gui', ['drawerPage']),
		...mapState('fonts', ['isLoadingFonts']),
		currentPage: ({ drawerPage }) => drawerPage[USER_STYLES_DRAWER],
		isLoadingUserStylesDrawer: ({
			isLoadingFonts,
			hasLoadedCurrentTemplateStyles,
		}) => (
			isLoadingFonts || !hasLoadedCurrentTemplateStyles
		),
	},
	created() {
		this.fetchGoogleFonts();
	},
	methods: {
		...mapActionsFonts({ fetchGoogleFonts: FETCH_GOOGLE_FONTS }),
		...mapActionsGui({ changePreviousDrawerPage: CHANGE_PREVIOUS_DRAWER_PAGE }),
	},
};
</script>

<style lang="scss" scoped>
@import '@/components/builder-drawers/drawers/StylesDrawer.scss';

.loading {
	display: flex;
	justify-content: center;
}
</style>
